<template>
    <v-dialog :persistent="assignmentLoading" v-model="submitDialog" max-width="700">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary">Hand-in</v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center w-100">
                    <span class="text-uppercase secondary--text ml-1">{{ bundleData.long_id }}</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="d-flex flex-column mt-3">
                    <v-alert type="info" outlined>
                        The content of the following files / folders will be handed in, please make sure they exist otherwise hand-in will not succeed!
                        <v-expansion-panels :value="[0]" flat multiple class="w-100">
                            <v-expansion-panel>
                                <v-expansion-panel-header class="px-0">
                                    <span class="secondary--text font-weight-bold">Files ({{ bundleData.objects.files.length }})</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list class="pa-0" dense>
                                        <v-list-item-group class="pa-0 ma-0" color="secondary">
                                            <v-list-item style="max-height: 5px" v-for="file in bundleData.objects.files" :key="file" class="pa-0">
                                                <v-list-item-avatar>
                                                    <v-icon small>mdi-file-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="subtitle-2">{{ file.slice(6) }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-alert>
                    <span class="subtitle-2">
                        Please provide a hand-in identifier (e.g. exam code, email address, etc..) that is in line with your course policy and allows the
                        instructor to match your work with your identifier. If unsure, please discuss the appropriate identifier with your instructor.
                    </span>
                    <v-text-field autofocus class="mt-3" label="Hand-in identifier" v-model="handinIdentifier" outlined></v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="!handinIdentifier"
                    :loading="assignmentLoading"
                    color="primary"
                    text
                    @click="submitAssignment(handinIdentifier, assignmentShortId, bundleData.bid)">
                    Hand-in
                </v-btn>
                <v-btn :disabled="assignmentLoading" color="secondary" text @click="submitDialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { assignmentMethods } from '@/mixins/assignments'
import { makeShortId } from '@/utils'

export default {
    name: 'SnapshotAssignmentSubmitDialog',
    mixins: [assignmentMethods],
    props: {
        bundleData: Object
    },
    data() {
        return {
            submitDialog: false,
            handinIdentifier: ''
        }
    },
    computed: {
        assignmentShortId() {
            if (this.handinIdentifier) {
                return makeShortId(this.handinIdentifier, 48)
            }
            return ''
        },
        currentInstance() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        }
    },
    mounted() {
        this.handinIdentifier = this.currentInstance
    },
    watch: {
        assignmentLoading: function (nextVal) {
            if (!nextVal && this.submitDialog) {
                this.handinIdentifier = ''
                this.submitDialog = false
            }
        },
        currentInstance: function (nextVal) {
            this.handinIdentifier = nextVal
        }
    }
}
</script>
